import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

const $smoothScroll = document.querySelectorAll('.js-smoothScroll');
for (const element of $smoothScroll) {
    element.addEventListener('click', () => {
        const destination = element.getAttribute('href');
        const element = document.querySelector(destination);
        if (destination){
            window.scroll({ top: element.offsetTop-100, behavior: 'smooth' });
        }
    });
}
