const canuserIntersectionObserver = ('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype);
const triggerModuleLoad = function(element){
    const moduleToLoad = element.dataset.module;
    element.classList.remove(`${moduleToLoad}--observing`);
    element.classList.add(`${moduleToLoad}--initiated`);
    import(/* webpackChunkName: "[request]" */`../modules/${moduleToLoad}`).then(function(module){
        if (typeof module.init === 'function') {
            module.init(element);
        } else if (typeof module.default.init === 'function') {
            module.default.init(element);
        }
    }).catch(function () {
    //}).catch(function(error){
        //console.log(error);
    });
};
const io = canuserIntersectionObserver ? new IntersectionObserver(
    function(entries) {
        for (const entriesRecord of entries) {
            const element = entriesRecord.target;
            const certainRatio = ('ratio' in element.dataset) ? parseFloat(element.dataset.ratio) : 0;
            const visible = entriesRecord.intersectionRatio > certainRatio;
            if (visible){
                triggerModuleLoad(element);
                io.unobserve(element);
            }
        }
    }, {
        root: null,
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
    }) : false;
const scan = function(selector){
    const items = selector.querySelectorAll('[data-module]');
    for (const element of items){
        const moduleToLoad = element.dataset.module;
        if (!element.classList.contains(`${moduleToLoad}--initiated`)){
            element.classList.add(`${moduleToLoad}--observing`);
            if (canuserIntersectionObserver && element.dataset.instant === undefined){
                io.observe(element);
            } else {
                triggerModuleLoad(element);
            }
        }
    }
};
const runModuleFunction = function(string, target){
    const splitFunction = string.split('::');
    if (splitFunction.length == 2){
    import(/* webpackChunkName: "[request]" */`./${splitFunction[0]}`).then(function(module){
        if (typeof module[splitFunction[1]] === 'function'){
            module[splitFunction[1]](target);
        } else if (typeof module.default[splitFunction[1]] === 'function') {
            module.default[splitFunction[1]](target);
        }
    });
    }
};
const apModuleLoader = {
    scan,
    run: runModuleFunction,
};
export default apModuleLoader;
